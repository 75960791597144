<template>
  <LayoutVertical />
</template>

<script>
import LayoutVertical from "@/shuhada-layouts/vertical/LayoutVertical.vue";
import { mapActions } from "vuex";
export default {
  components: {
    LayoutVertical,
  },
   created() {
    this.setNavMenuItems();
  },
  methods: {
    ...mapActions({ setNavMenuItems: "appShuhada/setNavMenuItems" }),
  },
};
</script>

<style>
</style>